import React from 'react';
import MainLayout from './layouts/MainLayout';

const LayoutWrapper = ({ layout = 'default', layoutProps, children }) => {

  return (
    <MainLayout layoutProps={layoutProps}>
      {children}
    </MainLayout>
  );
};

export default LayoutWrapper;