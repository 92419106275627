import React from 'react';
import Header from '../Header';

const MainLayout = ({ children, layoutProps }) => {
  const {
    collapsed,
    setCollapsed,
    sidebarContent,
    toggleRightSidebar,
    sidebarProps
  } = layoutProps;

  console.log('sidebarProps in MainLayout: ', sidebarProps);

  return (
    <div className="flex">
      <div className="flex-1 flex flex-col">
        <Header
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          toggleRightSidebar={toggleRightSidebar}
          sidebarContent={sidebarContent}
        />
        <main className="flex-1 overflow-auto p-2">
          {children}
        </main>
      </div>
    </div>
  );
};

export default MainLayout;