import React, { Suspense, lazy, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import LayoutWrapper from './components/LayoutWrapper';
import { AppProvider } from './context/AppContext';
import { ThemeProvider } from './context/ThemeContext';
import { ContentProvider } from './context/ContentContext';
import LoadingSpinner from './components/LoadingSpinner';
import ErrorBoundary from './components/ErrorBoundary';

// Lazy-loaded components
const Homepage = lazy(() => import('./pages/Homepage'));

function AppContent() {
  const [collapsed, setCollapsed] = useState(false);
  const [rightSidebarVisible, setRightSidebarVisible] = useState(false);
  const [sidebarContent, setSidebarContent] = useState('default');
  const [sidebarProps, setSidebarProps] = useState({});

  const toggleRightSidebar = (content = 'default', props = {}, onSuccess = null) => {
    console.log('toggleRightSidebar called with:', { content, props, onSuccess });
    setRightSidebarVisible(prevState => !prevState);
    setSidebarContent(content);
    setSidebarProps({ record: props, onSuccess: onSuccess });
  };

  const layoutProps = {
    collapsed,
    setCollapsed,
    rightSidebarVisible,
    setRightSidebarVisible,
    sidebarContent,
    toggleRightSidebar,
    sidebarProps
  };

  console.log('sidebarProps in App.js: ', sidebarProps);

  return (
    <ConfigProvider theme={{ /* Your theme configuration here */ }}>
      <div className="flex h-screen">
        <ErrorBoundary>
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route
                path="/"
                element={
                  <LayoutWrapper layoutProps={layoutProps}>
                    <Homepage />
                  </LayoutWrapper>
                }
              />
            </Routes>
          </Suspense>
        </ErrorBoundary>
      </div>
    </ConfigProvider>
  );
}

function App() {
  return (
    <AppProvider>
      <ThemeProvider>
        <ContentProvider>
            <AppContent />
        </ContentProvider>
      </ThemeProvider>
    </AppProvider>
  );
}

export default App;