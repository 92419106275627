import React, { useState, useEffect } from 'react';
import { Typography, Carousel } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const { Title } = Typography;

const Header = () => {
  const [sponsors] = useState([
    { name: 'Buy This Perfect AI Domain: Marathons.ai', url: 'https://domains.atom.com/lpd/name/marathons.ai' },
    { name: 'Premium Domain: CycledAi.com', url: 'https://domains.atom.com/lpd/name/cycledai.com' },
    { name: 'Premium Domain: ClarificationAI.com', url: 'https://domains.atom.com/lpd/name/ClarificationAI.com' },
    { name: 'Premium Domain: Snappar.com', url: 'https://domains.atom.com/lpd/name/Snappar.com' },
    { name: 'Premium Domain: Quran.house', url: 'https://domains.atom.com/lpd/name/Quran.house' },
    { name: 'Premium Domain: Flux1.me', url: 'https://domains.atom.com/lpd/name/Flux1.me' },
    { name: 'Premium Domain: Assistant.bz', url: 'https://domains.atom.com/lpd/name/assistant.bz' },
    { name: 'Premium Domain: AIcollate.com', url: 'https://domains.atom.com/lpd/name/aicollate.com' },
    { name: 'Premium Domain: Counslor.com', url: 'https://domains.atom.com/lpd/name/counslor.com' },
    { name: 'Premium Domain: District.food', url: 'https://domains.atom.com/lpd/name/district.food' },
    { name: 'Premium Domain: GSTPage.com', url: 'https://domains.atom.com/lpd/name/gstpage.com' },
    { name: 'Premium Domain: MakeWebAI.com', url: 'https://domains.atom.com/lpd/name/makewebai.com' },
    { name: 'Premium Domain: PhotoEncrypt.com', url: 'https://domains.atom.com/lpd/name/PhotoEncrypt.com' },
    { name: 'Premium Domain: xConference.co', url: 'https://domains.atom.com/lpd/name/xConference.co' },
  ]);

  const openSponsorLink = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className='bg-blue-500 px-8 py-3 text-white h-16 flex items-center'>
      <div className='w-1/3 mt-4'>
        <h2 className='text-white font-bold text-2xl'>LaunchPartyHQ 🎉🎈</h2>
      </div>
      <div className='w-2/3 flex items-center justify-end'>
        <Carousel
          autoplay
          autoplaySpeed={5000}
          dots={false}
          arrows
          prevArrow={<LeftOutlined className="text-white absolute -left-8 top-1/2 transform -translate-y-1/2" />}
          nextArrow={<RightOutlined className="text-white absolute -right-8 top-1/2 transform -translate-y-1/2" />}
          className='w-full max-w-3xl'
        >
          {sponsors.map((sponsor, index) => (
            <div key={index} className='text-center px-10'>
              <span 
                className='text-white cursor-pointer hover:underline'
                onClick={() => openSponsorLink(sponsor.url)}
              >
                {sponsor.name} 🚀
              </span>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default Header;