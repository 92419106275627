/**
 * ContentContext.js
 * 
 * This file defines a React Context for managing content state across components.
 * It provides a centralized way to store and update various types of content
 * without prop drilling.
 *
 * Key features:
 * 1. ContentContext: The React Context object for content management.
 * 2. ContentProvider: A component that wraps the app to provide the content context.
 * 3. useContentContext: A custom hook for easy access to the content context.
 *
 * Usage:
 * - Wrap your main app component with ContentProvider.
 * - Use the useContentContext hook in child components to access and update content.
 */

import React, { createContext, useState, useContext, useCallback } from 'react';

// Create a new context for content management
const ContentContext = createContext();

/**
 * ContentProvider component
 * 
 * This component provides the ContentContext to its children. It manages the
 * content state and provides a method to update it.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components to be wrapped
 */
export function ContentProvider({ children }) {
  // Initialize the content state as an empty object
  const [contentState, setContentState] = useState({});

  /**
   * updateContent function
   * 
   * This function updates a specific content type in the state.
   * It merges the new data with the existing data for the given content type.
   *
   * @param {string} contentType - The type of content to update
   * @param {Object} newData - The new data to merge with existing content
   */
  const updateContent = useCallback((contentType, newData) => {
    setContentState(prevState => ({
      ...prevState,
      [contentType]: {
        ...prevState[contentType],
        ...newData
      }
    }));
  }, []);

  // Provide the content state and update function to children
  return (
    <ContentContext.Provider value={{ contentState, updateContent }}>
      {children}
    </ContentContext.Provider>
  );
}

/**
 * useContentContext hook
 * 
 * This custom hook provides easy access to the ContentContext.
 * It can be used in any child component to read the content state
 * or update it using the updateContent function.
 *
 * @returns {Object} An object containing contentState and updateContent
 */
export function useContentContext() {
  return useContext(ContentContext);
}